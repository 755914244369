import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Styles from "../styles/components/ServicesSection.module.scss"
import WebsiteImage from "../images/website.svg"
import TechImage from "../images/tech.svg"
import AppImage from "../images/app.svg"
import Button from "./ctaButton"
import Cta from "./cta"

const ServicesSection = () => (
  <div className="container-padding container">
    <div className={Styles["section-line--title"]}>
      <h5>Our Services</h5>
    </div>
    <h1 className={Styles["section-title--big"]}>
      What we excel at doing
    </h1>
    <div className={Styles["section-divider"]}>
      <div className={Styles["section-divider--text"]}>
        <ScrollAnimation animateIn="fadeIn">
          <div className={Styles["section-text--divider"]}>
            <h4 className={Styles.service}>Websites</h4>
            <p>
              Delivering a stellar experience on your website is what your visitors deserve and your business needs. We make it a reality. We maintain and enhance the it goes along ensuring your business enjoy the latest and best web technologies, speed and interactions. 
            </p>
            <Button secondaryButton buttonTitle="Explore" buttonUrl='expertise/websites'></Button>
          </div>
        </ScrollAnimation>
      </div>
      <div className={Styles.serviceImage}>
        <img src={WebsiteImage} alt="Website design and development" />
      </div>
    </div>
    <div className={Styles["section-divider"]}>
      <div className={Styles["section-divider--text"]}>
        <ScrollAnimation animateIn="fadeIn">
          <div className={Styles["section-text--divider"]}>
            <h4 className={Styles.service}>Apps</h4>
            <p>
              Whether we're creating new digital products for you with mobile apps, or optimising business operations with desktop and cloud based applications. We deliver secure, reliable and scalable applications for your organization. 
            </p>
            <Button secondaryButton buttonTitle="Explore" buttonUrl='expertise/apps'></Button>
          </div>
        </ScrollAnimation>
      </div>
      <div className={Styles.serviceImage}>
        <img src={AppImage} alt="Application development " />
      </div>
    </div>
    <div className={Styles["section-divider"]}>
      <div className={Styles["section-divider--text"]}>
        <ScrollAnimation animateIn="fadeIn">
          <div className={Styles["section-text--divider"]}>
            <h4 className={Styles.service}>Tech Integration</h4>
            <p>
              New forces such as AI, ML, cloud computing and others are driving innovation today, we help educate you on how these technologies can propel your organisation, preparing your business for them, implementing and managing them. 
            </p>
            <Button secondaryButton buttonTitle="Explore " buttonUrl='expertise/tech'></Button>
          </div>
        </ScrollAnimation>
      </div>
      <div className={Styles.serviceImage}>
        <img src={TechImage} alt="tech integration feature image" />
      </div>
    </div>
    <Cta></Cta>
  </div>
)

export default ServicesSection
