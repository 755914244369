import React from "react"
import PageHero from "../../components/pagehero"
import Footer from "../../components/footer"
import ServicesSection from "../../components/ServicesSection"
import MobileHeader from "../../components/MobileHeader"

const Expertise = () => (
  <div>
    <MobileHeader></MobileHeader>
    <PageHero
      heading={[
        <span key="expertise">No guess work,</span>,
        " we can beat our chest on these areas ",
      ]}
      description="We've developed competency in these areas so we can solve your problems, no stories! Just solutions!!"
      imgSrc="/expertise-page.jpg"
      pageTitle="Expertise"
      secondaryButtonTitle="Free Consultation"
      secondaryButtonUrl="contact"
    ></PageHero>
    <ServicesSection></ServicesSection>
    <Footer></Footer>
  </div>
)

export default Expertise
